import React from 'react'
import { useState, useEffect } from 'react';
import PictureImage1 from 'assets/img/downloads/Picture1.png';
import PictureImage2 from 'assets/img/downloads/Picture2.png';
// import MicroGravityImage from 'assets/img/downloads/Microgravity.png';
import MicroGravityImage from 'assets/img/downloads/microgravitygif.gif';
// import MoonImage from 'assets/img/downloads/Moon.jpg';
import MoonImage from 'assets/img/downloads/moongif.gif';
// import MarsImage from 'assets/img/downloads/Mars.jpg';
import MarsImage from 'assets/img/downloads/marsgif.gif';
// import AsteroidImage from 'assets/img/downloads/Asteroid.jpg';
import AsteroidImage from 'assets/img/downloads/asteriod.gif';
import WaterImage from 'assets/img/downloads/watergif.gif';
import IceCapsImage from 'assets/img/downloads/icecapsgifs.gif';
import TerrainImage from 'assets/img/downloads/terraingifs.gif';
// import IndustryImage from 'assets/img/downloads/Industry.png';
import IndustryImage from 'assets/img/downloads/industrygif.gif';

import { Card, CardHeader, CardBody, Row, Col, CardTitle, Table, Button } from "reactstrap";
import { backgroundColors } from 'contexts/BackgroundColorContext';
function Download() {

  const [trackingData, setTrackingData] = useState([]);
  const [showFullSuitID, setShowFullSuitID] = useState(false);
  const [showFullSessionID, setShowFullSessionID] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');
        
        // Include the token in the Authorization header
        const response = await fetch('https://www.kernel360.kosmosuit.neurom.in/node-project/api/tracking', {
          method: 'GET', // Method is optional here as GET is the default value
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Use template literal to include the token
          },
        });

        const data = await response.json();
        console.log(data);
        setTrackingData(data.data); // Assuming the API returns an array of objects
      } catch (error) {
        console.error('Failed to fetch tracking data:', error);
      }
    };

    fetchData();
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const downloadCSV = () => {
    // Updated CSV header to include all the fields
    const csvHeader = 'Suit Mode,Suit ID,Session ID,Pre Flight,In Flight,Post Flight,Date,Start Time,End Time,View';
    
    // Mapping over trackingData to format each row as a CSV string
    const csvRows = trackingData.map(item => [
      item.suitMode,
      item.suitID,
      item.sessionID,
      item.preflight,
      item.inflight,
      item.postFlight,
      formatDate(item.date), // Assuming formatDate function is defined elsewhere to format date as 'year-mm-dd'
      item.start_time,
      item.end_time,
      item.view,
    ].join(','));
  
    // Prepending header to rows
    csvRows.unshift(csvHeader);
  
    // Joining all rows with newline characters
    const csvString = csvRows.join('\n');
    
    // Creating and triggering a download of the CSV file
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'tracking-data.csv';
    document.body.appendChild(a); // Append the anchor to the body to ensure compatibility in certain browsers
    a.click();
    document.body.removeChild(a); // Clean up
    URL.revokeObjectURL(url);
  };
  

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <p>By downloading, installing, or using this software, you agree to be bound by the terms and conditions of the following license agreement. Please read this agreement carefully.
                  Metakosmos company (“Kosmosuit") grants you a limited, non-exclusive license to use the upgrade software ("Software") in binary executable form in the normal operation of the applicable Metakosmos products. Title, ownership rights, and intellectual property rights in and to the Software remain in Kosmosuit. This license is personal to you and you may make copies of the software only for your personal use. You agree that this license agreement does not need to be signed for it to take effect.
                  The software is provided on an "as is" basis without warranty of any kind — either expressed or implied — including, without limitation, any implied warranties of merchantability or fitness for a particular purpose. Metakosmos does not warrant the performance of the software or that the software will meet your requirements or operate error free.
                  You acknowledge that this Software is the property of Metakosmos and is protected under Australia copyright laws and international copyright treaties. You further acknowledge that the structure, organization, and code of the Software are valuable trade secrets of Metakosmos and that the Software in source code form remains a valuable trade secret of Metakosmos. You agree not to decompile, disassemble, modify, reverse assemble, reverse engineer, or reduce to human readable form the Software or any part thereof or create any derivative works based on the Software. You agree not to export or re-export the Software to any country in violation of the export control laws of the United States of America & Defence Export Controls of Australia.</p>
              </CardHeader>
              <CardBody>
                <Row className='justify-content-center'>
                  <Col className='downloads' md="4" >
                    <button className='agree'>
                      <i class="fa fa-check" style={{ color: '#7abf6b', fontSize: '30px' }} aria-hidden="true"></i> I agree and start the download
                    </button>
                  </Col>
                  <Col className='downloads' md="4">
                    <button className='upgrade'>
                      <i class="fa fa-check" style={{ color: '#7abf6b', fontSize: '30px' }} aria-hidden="true"></i>     Upgrade Software to latest version
                    </button>
                  </Col>
                </Row>

                <br></br><br></br>
                <h2 style={{ color: '#b37032' }}>Download Environments</h2>
                <Row>
                  <Col md="3">
                    <img src={MicroGravityImage}></img>
                    <h3>Microgravity</h3>
                  </Col>
                  <Col md="3">
                    <img src={MoonImage}></img>
                    <h3>Moon</h3>
                  </Col>
                  <Col md="3">
                    <img src={MarsImage}></img>
                    <h3>Mars</h3>
                  </Col>
                  <Col md="3">
                    <img src={AsteroidImage}></img>
                    <h3>Asteroid</h3>
                  </Col>
                </Row>

                <br></br><br></br>
                <h2 style={{ color: '#b37032' }}>Terrestrial</h2>
                <Row>
                  <Col md="3">
                    <img src={WaterImage}></img>
                    <h3>Water</h3>
                  </Col>
                  <Col md="3">
                    <img src={IceCapsImage}></img>
                    <h3>Ice Caps</h3>
                  </Col>
                  <Col md="3">
                    <img src={TerrainImage}></img>
                    <h3>Terrain</h3>
                  </Col>
                  <Col md="3">
                    <img src={IndustryImage}></img>
                    <h3>Industry</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
          <Button onClick={downloadCSV} style={{background: 'rgba(1, 66, 106, 0.2)',border: '1px solid #01426a'}}>Download CSV</Button>
            <Table responsive>
              <thead>
                <tr>
                  <th>Suit Mode</th>
                  <th>Suit ID</th>
                  <th>Session Id</th>
                  <th>Pre Flight</th>
                  <th>In Flight</th>
                  <th>Post Flight</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>View</th>
                  {/* Add more headers based on your data structure */}
                </tr>
              </thead>
              <tbody>
                {trackingData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.suitMode}</td>
                    <td onClick={() => setShowFullSuitID(!showFullSuitID)}>
                      {showFullSuitID ? item.suitID : `${item.suitID.substring(0, 5)}..`}
                    </td>
                    <td onClick={() => setShowFullSessionID(!showFullSessionID)}>
                      {showFullSessionID ? item.sessionID : `${item.sessionID.substring(0, 5)}..`}
                    </td>
                    <td>{item.preflight}</td>
                    <td>{item.inflight}</td>
                    <td>{item.postFlight}</td>
                    <td>{formatDate(item.date)}</td>
                    <td>{item.start_time}</td>
                    <td>{item.end_time}</td>
                    <td>{item.view}</td>
                    {/* Fill in more data cells based on your data structure */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      </div>
    </>
  )
}

export default Download
