import React, { useEffect,useContext } from 'react';
import HomeImage from 'assets/images/home.svg';
import LogoImage from 'assets/images/logo.svg';
import ArrowImage from 'assets/images/arrow.svg';
import { useNavigate } from 'react-router-dom';
import 'assets/css/header.css';
import { Link } from 'react-router-dom';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
import Home from 'front/Home';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table
  } from "reactstrap";
function Header(props) {
  const navigate = useNavigate();

  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
    const [modalSearch, setmodalSearch] = React.useState(false);
  const [modalShown, setModalShown] = React.useState(false);
    const [navbarName, setNavbarName] = React.useState("Session Setup");
    const { stageFlight, suitMode, activity,activitiesByFlightStage, changeStageFlight,changeSuitMode,changeActivity } = useContext(FrontHeaderDataContext);

  const handleStageFlightChange = (e) => {
    changeStageFlight(e.target.value);
  };

  function generateCustomId(user) {
    const firstInitial = user.first_name.charAt(0).toUpperCase();
    const lastInitial = user.last_name.slice(-1).toUpperCase();
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    
    // Assuming you want to use the first letter of the user's _id for the count
    // and just for demonstration, I'm using the numeric part of the _id for simplicity
    // In a real scenario, this should be a user count/sequence number specific to your application logic
    let userIdPart = user._id.replace(/[^\d]/g, '').slice(0, 3); // Extracts first 3 numeric characters from _id
    
    return `${firstInitial}${lastInitial}${year}${month}${userIdPart}`;
  }
  
  // Assuming you've already stored the user data in localStorage
  function retrieveAndGenerateId() {
    // Retrieve the user data string from localStorage
    const userDataString = localStorage.getItem('user');
    
    if (userDataString) {
      const user = JSON.parse(userDataString);
      const customId = generateCustomId(user);
      console.log(customId);
      return customId;
    } else {
      console.error('No user data found in localStorage');
      return null;
    }
  }
  
  // Example usage
  

const customId = retrieveAndGenerateId();
  

  const handleSuitModeChange = (e) => {
    changeSuitMode(e.target.value);
  };

  const handleActivityChange = (e) => {
    changeActivity(e.target.value);
  };

  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const sessionModalSearch = () => {
    setModalShown(!modalShown);
  };
  
  const handleModalOpen = () => {
    const modalContentDiv = document.querySelector('.modal-content');
    if (modalContentDiv) {
      modalContentDiv.style.marginBottom = '20px';
      modalContentDiv.classList.add('card');
      
    }
  };
    useEffect(() => {
        
        const routeWithoutSlash = props.currentRoute.replace(/^\/|\/$/g, '');
        console.log(routeWithoutSlash)
        if (routeWithoutSlash === "admin/metrics") {
            sessionModalSearch();
             setTimeout(() => {
              setModalShown(false);
            }, 5000);
      
            // Clean up the timeout to avoid memory leaks
            // return () => clearTimeout(delay);
          }
        switch (routeWithoutSlash) {
            case 'home':
                setNavbarName('Session Id');
                break;
            case 'digital-fit':
                setNavbarName("Digital Fit");
                break;
            case 'simulate':
                setNavbarName("Simulate");
                break;
            case 'simulate/view':
                setNavbarName("Simulate");
                break;
            case 'simulate/analytics':
                setNavbarName("Simulate");
                break;
            case 'dashboard':
                setNavbarName("Dashboard");
                break;
            case 'suit':
                setNavbarName("Suite Library");
                break;
            case 'creat-suit':
                setNavbarName("Create Suit");
                break;
            case 'simulate-state':
                setNavbarName("Simulate");
                break;
            case 'biomechanics':
               setNavbarName("Bio Mechanics");
               break;
            case 'summary':
              setNavbarName('Session Summary');
              break;
            case 'environment':
              setNavbarName('Environment');
              break;
            // Add more cases as needed for different routes
            default:
                // Use the default name if the route doesn't match any specific case
                if (routeWithoutSlash.includes('admin')) {
                    setNavbarName('Data Manager');
                } else {
                    // Use the default name if the route doesn't match any specific case
                    break;
                }
                break;
        }
    
        // setNavbarName(navbarName);
    }, [props.currentRoute]);

    const handleLogout = async () => {
      try {
        const response = await fetch('https://www.kernel360.kosmosuit.neurom.in/node-project/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Include the token in the headers for authentication
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token from localStorage
          }
        });
    
        if (response.ok) {
          console.log('Logout successful');
          // Optionally, clear token from localStorage or context/state
          localStorage.removeItem('token');
          localStorage.removeItem('token_expiration');
          localStorage.removeItem('user');
    
          // Optionally, perform additional actions after logout
          // For example, navigate to the landing page after successful logout
          navigate('/');
        } else {
          const errorData = await response.json();
          console.error('Logout failed', errorData);
          // Handle errors (show to user, log, etc.)
        }
      } catch (error) {
        console.error('Logout request failed', error);
        // Handle network errors, show user feedback
      }
    };

    const handleMouseEnter = () => {
      setIsTooltipVisible(true);
    };
  
    const handleMouseLeave = () => {
      setIsTooltipVisible(false);
    };

    return (
        <div style={{background:'#14181B'}}>
            <header className="app-header">
                <div className="menu-heading">
                    <Link to="/" aria-label="Home" className=" home-logo menu-item" alt="home" style={{background:'#14181B',borderTop:'1px solid #14181B'}}> 
                        <img src={HomeImage} />
                    </Link>
                    <div className="vl" />
                    <img src={LogoImage} className="logo menu-item" alt="mrl logo" />
                    <div className="vl" />
                    <div id="title-portal" className="title text-white">
                      {/* {navbarName} */}
                      {customId}
                      </div>
                </div>
                <div className="selector-container" style={{ display: navbarName === "Session Setup" ? 'none' : '' }}>
                    <div className="selector text-white">
                    <select disabled={true} className='text-white' name="stageFlight" value={stageFlight} onChange={handleStageFlightChange}>
                        <option value="Pre Flight" className="options text-white">Pre Flight</option>
                        <option value="In Flight" className="options text-white">In Flight</option>
                        <option value="Post Flight" className="options text-white">Post Flight</option>
                    </select>
                        <div className="selector-label">
                            <label>{stageFlight}</label>
                            <img src={ArrowImage} />
                        </div>
                    </div>
                    <div className="selector text-white">
                        <select disabled={true} className='text-white' name="suitMode" value={suitMode} onChange={handleSuitModeChange}>
                            <option value="Virtual Suit" className="options text-white">Virtual Suit</option>
                            <option value="Real Suit" className="options text-white">Real Suit</option>
                        </select>
                        <div className="selector-label">
                            <label>{suitMode}</label>
                            <img src={ArrowImage} />
                        </div>
                    </div>
                    <div className="selector text-white">
                        <select disabled={true} className='text-white' name="activity" value={activity} onChange={handleActivityChange}>
                        {activitiesByFlightStage[stageFlight].map((activityOption, index) => (
                          <option key={index} value={activityOption} className="options text-white">{activityOption}</option>
                        ))}
                        </select>
                        <div className="selector-label">
                        <React.Fragment>
                        <label onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                          {activity.length <= 10 ? activity : `${activity.slice(0, 20)}...`}
                        </label>
                        {isTooltipVisible && (
                          <div className="tooltip">
                            {activity}
                          </div>
                        )}
                      </React.Fragment>
                            <img src={ArrowImage} />
                        </div>
                    </div>
                     
                </div>
                <Button color="link" onClick={toggleModalSearch} style={{ display: navbarName === "Session Setup" ? 'none' : '' }}>
                        <i className="text-white h4 fa fa-search" />
                        <span className="d-lg-none d-md-block">Search</span>
                        </Button>
                         <Button color="link" className='text-white' onClick={handleLogout} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <i className="fa fa-sign-out-alt text-white" />Logout {/* Logout icon */}
                          </Button>
                <div />
            </header>

            <Modal
        modalClassName="modal-search"
        isOpen={modalShown}
        toggle={sessionModalSearch}
        onOpened={handleModalOpen}
      >
        <ModalHeader> 
        <Row className="ml-5" style={{fontFamily:'Alternox-ExLight'}}>
        <Col className="col-3" style={{ textAlign: 'justify', whiteSpace: 'nowrap' }}>
          <p>Date: {new Date().toLocaleDateString()}</p>
        </Col>
        <Col className="col-3" >
          <p>Suit ID: KS 001</p>
        </Col>
        <Col className="col-3" >
          <p>Session ID: KS 001 aced1567</p>
        </Col>
        <Col className="col-3" >
          <p>Name: John Doe</p>
        </Col>
        <Col className="col-3" >
          <p>Age: 26</p>
        </Col>
      </Row>
      <Row className="ml-5" style={{fontFamily:'Alternox-ExLight'}}>
        <Col className="col-3" >
          <p>Gender: Male</p>
        </Col>
        <Col className="col-3" >
          <p>Ethnicity: Asian</p>
        </Col>
        <Col className="col-3" >
          <p>Stage: Preflight</p>
        </Col>
        <Col className="col-3" >
          <p>Vessel: NASA</p>
        </Col>
        <Col className="col-3" >
          <p>Location: GPSEnabled</p>
        </Col>
      </Row>
      <button
            aria-label="Close"
            className="close"
            onClick={sessionModalSearch}
          >
            <i className="fa fa-close" />
          </button>
          {/* <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="fa fa-close" />
          </button> */}
          
        </ModalHeader>
      </Modal>

      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
        onOpened={handleModalOpen}
      >
        <ModalHeader> 
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="fa fa-remove" />
          </button>
        </ModalHeader>
      </Modal>
        </div>

        
    );
}

export default Header;