import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Card, Button, CardBody } from 'reactstrap';

export const ApiDataComponent = ({ show, handleClose }) => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await fetch('https://www.kernel360.kosmosuit.neurom.in/node-project/api/piwebapi/right-glove/data');
      const result = await response.json();
      setApiData(result.Items); // Store the API data
      setLoading(false); // Stop loading after data is fetched
    } catch (error) {
      console.error('Error fetching the API data:', error);
      setLoading(false);
    }

    const modalContentDiv = document.querySelector('.modal-content');
      if (modalContentDiv) {
          modalContentDiv.style.background = '#1E2429'; // Apply the new style
          modalContentDiv.style.width = '70vw';

          const modalTitle = modalContentDiv.querySelector('.modal-title');
          if (modalTitle) {
              modalTitle.style.color = '#fff'; // Apply color to the modal-title
          }
      }
  };

  // Fetch data when modal is opened
  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show]);

  return (
    <Modal isOpen={show} toggle={handleClose} size="lg" centered>
      <ModalHeader toggle={handleClose}>Right Glove PI Data</ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <Card style={{ width: '100%', margin: '0 auto' }}>
            <CardBody style={{ padding: '15px', maxHeight: '400px', overflowY: 'auto' }}>
              <Table  style={{ fontSize: '14px',textAlign:'left' }}>
                <thead>
                  <tr>
                    <th>Timestamp</th>
                    <th>Value (Name, Value)</th>
                    <th>Good</th>
                    <th>Questionable</th>
                    <th>Substituted</th>
                    <th>Annotated</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.length > 0 ? (
                    apiData.map((item, index) => (
                      <tr key={index}>
                        <td>{new Date(item.Timestamp).toLocaleString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: true
                            })}</td>
                        <td>
                          <div><strong>Name:</strong> {item.Value?.Name || 'N/A'}</div>
                          <div><strong>Value:</strong> {item.Value?.Value || 'N/A'}</div>
                          {/* <div><strong>IsSystem:</strong> {item.Value?.IsSystem ? 'Yes' : 'No'}</div> */}
                        </td>
                        <td>{item.Good !== undefined ? item.Good.toString() : 'N/A'}</td>
                        <td>{item.Questionable !== undefined ? item.Questionable.toString() : 'N/A'}</td>
                        <td>{item.Substituted !== undefined ? item.Substituted.toString() : 'N/A'}</td>
                        <td>{item.Annotated !== undefined ? item.Annotated.toString() : 'N/A'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </ModalBody>
      <div className="modal-footer">
        {/* <Button color="secondary" onClick={handleClose}>
          Close
        </Button> */}
      </div>
    </Modal>
  );
};
